import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Form } from "./components/sections/Form";
import { Title } from "./components/sections/Title";
import 'react-toastify/dist/ReactToastify.css';
import { Status } from "./components/sections/Status";
import "./App.css"
import { Footer } from "./components/sections/Footer";
import { Header } from "./components/sections/Header";
import { Faqs } from "./components/sections/Faqs";
import { SiteTitle } from "./config";

function App() {
    useEffect(() => {
        document.title = SiteTitle
    }, [])


    return (
        <div className="h-screen flex flex-col justify-between">
            <Header />
            <div className="container m-auto p-4 my-24">
                <div className="w-[600px] max-w-full mx-auto text-center">
                    <Title />
                    <Form />
                    <Faqs />
                </div>
            </div>
            <ToastContainer position={toast.POSITION.BOTTOM_CENTER} className="w-[400] md:w-full" />
            <Footer />
        </div>
    );
}

export default App;
