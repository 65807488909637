import { FunctionComponent } from "react";
import { BlockExplorer, FaucetAmount, FaucetCurrency } from "../config";
import { shortAddress } from "../helpers/address";

export interface Props {
    receipt: any;
}

export const SuccessToast: FunctionComponent<Props> = (props) => {
    const txLink = `${BlockExplorer}/tx/${props.receipt.hash}`;
    return (
        <div className="p-2 gap-2 flex flex-col">
            <div>
                <b className="mr-1">
                    {FaucetAmount}
                    {FaucetCurrency} Sent
                </b>
                to <code>{props.receipt.address}</code>
            </div>
            <div>
                <a
                    href={txLink}
                    target="_blank"
                    className="underline text-blue-500 cursor-pointer"
                >
                    View Transaction
                </a>
            </div>
        </div>
    );
};
