import { FunctionComponent } from "react";
import { FaucetAmount, FaucetCurrency, FaucetLimit, SiteTitle } from "../../config";

export const Title: FunctionComponent = () => {
    return (
        <div className="text-center flex flex-col gap-4 justify-center items-center relative">
            <img src="/faucet.png" className="max-w-full w-[100px] mb-4" />
            <h1 className="text-5xl font-bold text-black">{SiteTitle}</h1>
            <p>
                You can enter your address below to claim {FaucetAmount} {FaucetCurrency}{" "}
                once every {FaucetLimit}. 
            </p>
        </div>
    );
};

