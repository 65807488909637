import JSConfetti from "js-confetti";
import { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";
import {
    BlockExplorer,
    FaucetAmount,
    FaucetCurrency,
    FaucetURL,
} from "../../config";
import { showError } from "../../helpers/error";
import { validateAddress } from "../../helpers/validate";
import { Btn } from "../Btn";
import { Input } from "../Input";
import { Modal } from "../Modal";
import { SuccessToast } from "../Success";
import { Captcha } from "./Captcha";

export const Form: FunctionComponent = () => {
    const [address, setAddress] = useState("");
    const [modalOpen, setModalOpen] = useState(false);

    const triggerNext = () =>
        validateAddress(address)
            .then(() => setModalOpen(true))
            .catch((err) => showError("Invalid address" ,err));

    const onSubmit = async (captchaId: string, captchaSolution: string) => {
        const res = await fetch(FaucetURL + "/claim?address=" + address, {
            headers: {
                "X-Captcha": captchaId,
                "X-Captcha-Solution": captchaSolution,
            },
        });

        if (res.status == 498) throw new Error("invalid captcha");

        setModalOpen(false)
        if (res.status == 400) return showError(await res.text());
        if (res.status == 429) return showError(await res.text());
        if (res.status != 200) return showError("something went wrong");

        const receipt = await res.json();
        console.log("tx sent:", receipt);
        toast.success(<SuccessToast receipt={receipt} />, { autoClose: false });

        const jsConfetti = new JSConfetti()
        jsConfetti.addConfetti()
    };

    return (
        <div>
            <div className="flex justify-between my-6 mt-8 mx-auto w-[400px] max-w-full gap-2 flex-col md:flex-row">
                <Input
                    className="shadow-none focus:shadow-blue-200 focus:shadow-lg transition-shadow"
                    placeholder="wallet address"
                    value={address}
                    setValue={setAddress}
                />
                <Btn onClick={triggerNext} disabled={address.length < 1}>Claim {FaucetCurrency}</Btn>
                <Modal open={modalOpen} setOpen={setModalOpen}>
                    <Captcha submit={onSubmit} />
                </Modal>
            </div>
        </div>
    );
};
