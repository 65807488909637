import { FunctionComponent } from "react";

export interface Props {
    onClick?: Function;
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    children?: any;
}

export const Btn: FunctionComponent<Props> = (props) => {
    return (
        <button
            className={`rounded bg-blue-500 text-white px-4 py-2 w-full md:w-[200px] disabled:opacity-50 ${
                props.className || ""
            }`}
            onClick={() => props.onClick && props.onClick()}
            disabled={props.disabled || props.loading}
        >
            {props.loading ? "..." : props.children}
        </button>
    );
};
