import { useEffect, useState } from "react"
import { FaucetURL } from "../../config"

export const Status = () => {
    const [healthy, setHealthy] = useState(false)

    useEffect(() => {
        fetch(FaucetURL + "/health")
        .then(res => setHealthy(res.status == 200))
        .catch(() => setHealthy(false))
    }, [])

    return <>
    Status: { healthy ? <span className="text-green-500">Online</span> : <span className="text-red-500">Offline</span> }
    </>
}