import { FunctionComponent, useState } from "react";
import { ReactComponent as CloseIcon } from "./svg/close.svg";

export interface Props {
    open: boolean;
    setOpen: (v: boolean) => void;
    title?: string;
    children: any;
}

export const Modal: FunctionComponent<Props> = (props) => {
    const closeModal = () => props.setOpen(false);

    if (!props.open) {
        return <></>;
    }

    return (
        <div
            className={`fixed w-full h-screen overflow-scroll z-50 top-0 left-0 right-0 bottom-0 overflow-x-hidden fade-in`}
        >
            <div
                className="absolute w-full h-full opacity-50 bg-black cursor-default"
                onClick={closeModal}
            />

            <div className="relative mx-auto z-50 w-full md:w-[400px] my-24 p-2 md:p-0 gelatine-in">
                <div className="rounded bg-white shadow-xl">
                    <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 ">
                            Captcha
                        </h3>
                        <button
                            onClick={closeModal}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                            <CloseIcon className="w-5 h-5"/>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="p-6">{props.children}</div>
                </div>
            </div>
        </div>
    );
};
