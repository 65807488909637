import { FunctionComponent } from "react"

export interface Props {
    title: string
    children: any
}

export const Details: FunctionComponent<Props> = (props) => {
    return <div>
        <h2 className="font-bold opacity-75">{props.title}</h2>
        <div className="opacity-50">
            {props.children}
        </div>
    </div>
}