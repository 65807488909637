export const SiteTitle = "Pegasus Faucet"
export const FaucetLimit = "12 hours"
export const FaucetCurrency = "ETH"
export const FaucetAmount = "0.01"
export const FaucetURL = "https://web-production-9572.up.railway.app"
export const BlockExplorer = "https://pegasus.lightlink.io"
export const FaucetAddress = "0xF025f706564a24958EEf99a9eA56FfC935766faa"
export const FaucetNetwork = "Pegasus"
export const Github = "https://github.com/orbitool/faucet"

export const FooterMenu = {
    Main: [{
        display: "Explorer",
        url: BlockExplorer,
    },
    {
        display: "Health",
        url: `${FaucetURL}/health`
    },
    {
        display: "Faucet address",
        url: `${BlockExplorer}/address/${FaucetAddress}`,
    },
    {
        display: "Github",
        url: Github
    }] as linkData[],
    Secondary: [{
        display: "LinkLink",
        url: "https://lightlink.io"
    },{
        display: "Developer docs",
        url: "https://docs.lightlink.io"
    }] as linkData[]
}

interface linkData {
    display: string
    url: string
}