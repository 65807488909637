import { FunctionComponent } from "react";

export const Header: FunctionComponent = () => {
    return (
        <div className="container p-4 py-8 mx-auto md:p-2">
            <div className="flex justify-between align-middle items-center">
                <div className="text-2xl">
                    <a href="#">
                        <img
                            src="/faucet.png"
                            className="w-[32px] inline-block mr-2"
                        />
                        Faucet
                    </a>
                </div>

                <div className="flex gap-2 flex-end">
                    <a href="#faqs">FAQs</a>
                </div>
            </div>
        </div>
    );
};
