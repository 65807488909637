import { Fragment, FunctionComponent, useState } from "react";
import { ReactComponent as PlaceholderImage } from "./svg/placeholder.svg";

interface Props {
    src: string;
}

export const CaptchaImage: FunctionComponent<Props> = ({ src }) => {
    const [loading, setLoading] = useState(true);

    return (
        <Fragment>
            {loading && (
                <div className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
                    <div className="mx-auto flex items-center justify-center w-full md:w-[240px] h-[80px] bg-gray-300 rounded">
                        <PlaceholderImage className="w-12 h-12 text-gray-200" />
                    </div>
                </div>
            )}
            <img
                style={{ display: loading ? "none" : "block" }}
                className="mx-auto max-w-full"
                src={src}
                onLoad={() => setLoading(false)}
            />
        </Fragment>
    );
};
