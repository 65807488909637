import { FunctionComponent, useEffect, useState } from "react";
import { FaucetURL } from "../../config";
import { showError } from "../../helpers/error";
import { Btn } from "../Btn";
import { CaptchaImage } from "../CaptchaImage";
import { Input } from "../Input";
import { ReactComponent as ReloadIcon } from "../svg/reload.svg";


export interface Props {
    submit: (captchaId: string, captchaSolution: string) => Promise<any>;
}

export const Captcha: FunctionComponent<Props> = ({ submit }) => {
    const [id, setId] = useState("");
    const [solution, setSolution] = useState("");
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState("")

    useEffect(() => {
        setLoading(true);
        fetch(FaucetURL + "/captcha/create")
            .then(async (res) => setId(await res.text()))
            .catch((err) => console.error("error:", err))
            .finally(() => setLoading(false));
    }, []);

    const trySubmit = () => {
        setLoading(true);
        submit(id, solution)
            .then(() => console.log("captcha correct"))
            .catch((err) => showError("invalid captcha", err))
            .finally(() => setLoading(false));
    };

    const tryReload = async () => {
        setLoading(true)
        setReload(`?reload=${Date.now().toString()}`)
        setTimeout(() => setLoading(false), 1000)
    }

    const captchaImg = `${FaucetURL}/captcha/challenge/${id}.png${reload}`;

    return (
        <div className="p-6 space-y-6">
            <p>Please solve the Captcha below to prove you're not a robot.</p>
            <div className="text-center flex flex-col gap-4">
                {/* <img className="mx-auto max-w-full" src={captchaImg} /> */}
                <CaptchaImage src={captchaImg} />
                <div className="flex justify-between">
                    <Input
                        value={solution}
                        setValue={setSolution}
                        placeholder="captcha digits"
                        className="rounded-r-none"
                    />
                    <button className="rounded rounded-l-none px-3 bg-gray-300" disabled={loading} onClick={tryReload}><ReloadIcon className="w-5 h-5" /></button>
                </div>
                <Btn
                    loading={loading}
                    className="w-full !block min-w-full"
                    disabled={solution.length < 4}
                    onClick={trySubmit}
                >
                    Submit
                </Btn>
            </div>
        </div>
    );
};
