const ethRx = /^0x[a-fA-F0-9]{40}$/

export const validateAddress = async (address: string) => {

    if (address.length < 42)
        throw new Error("address too short")

    if (address.length > 42)
        throw new Error("address too long")

    if (!ethRx.test(address))
        throw new Error("invalid address format")

}