import { FunctionComponent } from "react";

export interface Props {
    className?: string
    value: string;
    setValue: (v: string) => void;
    placeholder?: string;
}

export const Input: FunctionComponent<Props> = (props) => {
    return (
        <input
            className={`w-full rounded bg-gray-200 text-black px-4 py-2 border-0 ${props.className || ""}`}
            placeholder={props.placeholder}
            value={props.value}
            onInput={(e) => props.setValue(e.currentTarget.value)}
        />
    );
};
