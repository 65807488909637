import { FunctionComponent } from "react";
import { FaucetNetwork, FaucetURL, FooterMenu } from "../../config";
import { Status } from "./Status";

export const Footer: FunctionComponent = () => {
    return (
        <div className="container p-4 md:p-2 mx-auto !pb-10">
            <h4 className="border-b-2 border-gray-100 pb-2 mb-4 flex justify-between">
                <h4>Faucet Version 0.4</h4>
                <span><a className="cursor-pointer" href={FaucetURL+"/health"}><Status /></a></span>
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2">
                <div className="flex flex-col gap-2">
                    {FooterMenu.Main.map((link) => (
                        <div>
                            <a
                                className="opacity-50 hover:opacity-100 transition-opacity"
                                href={link.url}
                            >
                                {link.display}
                            </a>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col gap-2">
                    {FooterMenu.Secondary.map((link) => (
                        <div>
                            <a
                                className="opacity-50 hover:opacity-100 transition-opacity"
                                href={link.url}
                            >
                                {link.display}
                            </a>
                        </div>
                    ))}
                    <div >
                        <button className="opacity-50 hover:opacity-100 transition-opacity">Add {FaucetNetwork} to Metamask</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
