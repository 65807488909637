import { FunctionComponent } from "react";
import {
    BlockExplorer,
    FaucetAmount,
    FaucetCurrency,
    FaucetLimit,
    FaucetNetwork,
} from "../../config";
import { Details } from "../Detail";

export const Faqs: FunctionComponent = () => {
    return (
        <div
            id="faqs"
            className="rounded bg-gray-100 p-4 mt-40 mx-auto text-left pb-8"
        >
            <h1 className="mb-2 font-bold">FAQS</h1>
            <hr className="mb-8" />

            <div className="flex flex-col gap-8">
                <Details title="How does this work?">
                    You can request up to {FaucetAmount}
                    {FaucetCurrency} for the {FaucetNetwork} testnet every{" "}
                    {FaucetLimit}!
                </Details>

                <Details title="How does I use this?">
                    Just enter your {FaucetNetwork} network wallet address and
                    press the "Claim {FaucetCurrency}" button. You may have to
                    fill out a captcha to prove your not a robot.
                </Details>

                <Details title="I got an error saying 'Invalid address'. Why?">
                    We only support Ethereum-based wallet addresses, which are
                    42 characters long, starting with '0x', and only including
                    letters a-F and 0-9. We don't support any other wallet
                    types.
                </Details>

                <Details title="I got an error saying 'Invalid Captcha'. What should I do?">
                    Press the reload button near the captcha and try again.
                </Details>

                <Details title="It says token sent, but I haven't received them?">
                    It can take some time for tokens to be processed by the
                    network. Ensure you wallet is connected to the{" "}
                    {FaucetNetwork} network, otherwise check to see if they have
                    arrived by the block explorer.{" "}
                    <a className="underline" href={BlockExplorer}>
                        Visit block explorer
                    </a>
                </Details>

                <Details title="I got 'This address has already claimed recently' error. What does this mean? ">
                    To prevent abuse and ensure everybody can get some test
                    tokens we have limited the service, so you can only claim
                    once every {FaucetLimit}.
                </Details>
            </div>
        </div>
    );
};
